import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Sending custom HTTP headers";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "sending-custom-http-headers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#sending-custom-http-headers",
        "aria-label": "sending custom http headers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sending custom HTTP headers`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-clientswithheaders"
        }}>{`Using Clients.withHeaders()`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-clientoptionheaders"
        }}>{`Using ClientOption.HEADERS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-clientbuilderdecorator"
        }}>{`Using ClientBuilder.decorator()`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-a-derived-client"
        }}>{`Using a derived client`}</a></li>
    </ul>
    <p>{`When sending an RPC request, it is sometimes required to send HTTP headers with it, such as an authentication token.
There are four ways to customize the HTTP headers of your RPC request:`}</p>
    <ul>
      <li parentName="ul">{`Using the `}<a parentName="li" {...{
          "href": "type://Clients#withHeaders(Consumer):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Clients.html#withHeaders(java.util.function.Consumer)"
        }}>{`type://Clients#withHeaders(Consumer)`}</a>{` method`}</li>
      <li parentName="ul">{`Using the `}<a parentName="li" {...{
          "href": "type://ClientOption#HEADERS"
        }}>{`type://ClientOption#HEADERS`}</a>{` option`}</li>
      <li parentName="ul">{`Using the `}<a parentName="li" {...{
          "href": "type://ClientBuilder#decorator(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientBuilder.html#decorator(java.util.function.Function)"
        }}>{`type://ClientBuilder#decorator(Function)`}</a>{` method`}</li>
      <li parentName="ul">{`Using a derived client`}</li>
    </ul>
    <h2 {...{
      "id": "using-clientswithheaders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-clientswithheaders",
        "aria-label": "using clientswithheaders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<inlineCode parentName="h2">{`Clients.withHeaders()`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import static com.linecorp.armeria.common.HttpHeaderNames.AUTHORIZATION;
import com.linecorp.armeria.common.util.SafeCloseable;
import com.linecorp.armeria.client.Clients;

HelloService.Iface client = Clients.newClient("tbinary+http://example.com/hello",
                                               HelloService.Iface.class);
try (SafeCloseable ignored = Clients.withHeaders(
        headers -> headers.set(AUTHORIZATION, credential))) {
    client.hello("authorized personnel");
}
`}</code></pre>
    <p>{`If you are setting only a single header,
you can use `}<a parentName="p" {...{
        "href": "type://Clients#withHeader(CharSequence,String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Clients.html#withHeader(java.lang.CharSequence,java.lang.String)"
      }}>{`type://Clients#withHeader(CharSequence,String)`}</a>{` simply:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`try (SafeCloseable ignored = Clients.withHeader(AUTHORIZATION, credential)) {
    client.hello("authorized personnel");
}
`}</code></pre>
    <p>{`You can also nest `}<inlineCode parentName="p">{`withHeader(s)`}</inlineCode>{`. The following example will send both `}<inlineCode parentName="p">{`user-agent`}</inlineCode>{` header and
`}<inlineCode parentName="p">{`authorization`}</inlineCode>{` header when calling `}<inlineCode parentName="p">{`client.hello()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import static com.linecorp.armeria.common.HttpHeaderNames.USER_AGENT;

try (SafeCloseable ignored1 = Clients.withHeader(USER_AGENT, myUserAgent)) {
    for (String cred : credentials) {
        try (SafeCloseable ignored2 = Clients.withHeaders(AUTHORIZATION, cred)) {
            client.hello("authorized personnel");
        }
    }
}
`}</code></pre>
    <h2 {...{
      "id": "using-clientoptionheaders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-clientoptionheaders",
        "aria-label": "using clientoptionheaders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<inlineCode parentName="h2">{`ClientOption.HEADERS`}</inlineCode></h2>
    <p>{`If you have a custom HTTP header whose value does not change often, you can use
`}<a parentName="p" {...{
        "href": "type://ClientOption#HEADERS"
      }}>{`type://ClientOption#HEADERS`}</a>{` which is more efficient:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import static com.linecorp.armeria.common.HttpHeaderNames.AUTHORIZATION;
import com.linecorp.armeria.common.HttpHeaders;
import com.linecorp.armeria.client.ClientBuilder;
import com.linecorp.armeria.client.ClientOption;

ClientBuilder cb = Clients.builder("tbinary+http://example.com/hello");
cb.setHeader(AUTHORIZATION, credential);
// or:
// cb.option(ClientOption.HEADERS, HttpHeaders.of(AUTHORIZATION, credential));
HelloService.Iface client = cb.build(HelloService.Iface.class);
client.hello("authorized personnel");
`}</code></pre>
    <h2 {...{
      "id": "using-clientbuilderdecorator",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-clientbuilderdecorator",
        "aria-label": "using clientbuilderdecorator permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<inlineCode parentName="h2">{`ClientBuilder.decorator()`}</inlineCode></h2>
    <p>{`If you want more freedom on how you manipulate the request headers, use a decorator:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ClientBuilder cb = Clients.builder("tbinary+http://example.com/hello");

// Add a decorator that inserts the custom header.
cb.decorator((delegate, ctx, req) -> { // See DecoratingHttpClientFunction and DecoratingRpcClientFunction.
    HttpRequest newReq = req.withHeaders(req.headers().toBuilder().set(AUTHORIZATION, credential));
    ctx.updateRequest(newReq);
    return delegate.execute(ctx, newReq);
});

HelloService.Iface client = cb.build(HelloService.Iface.class);
client.hello("authorized personnel");
`}</code></pre>
    <p>{`Note that this method is as efficient as the `}<a parentName="p" {...{
        "href": "type://ClientOption#HEADERS"
      }}>{`type://ClientOption#HEADERS`}</a>{` option.
Choose whichever you prefer.`}</p>
    <h2 {...{
      "id": "using-a-derived-client",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-a-derived-client",
        "aria-label": "using a derived client permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using a derived client`}</h2>
    <p>{`Although not as simple as using `}<a parentName="p" {...{
        "href": "type://Clients#withHeader(CharSequence,String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Clients.html#withHeader(java.lang.CharSequence,java.lang.String)"
      }}>{`type://Clients#withHeader(CharSequence,String)`}</a>{`,
you can create a derived client to add more custom headers to an existing client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HelloService.Iface client = ...;
HelloService.Iface derivedClient = Clients.newDerivedClient(client, options -> {
    return ClientOptions.builder()
                        .options(options)
                        .decorator(...)  // Add a decorator.
                        .addHeader(AUTHORIZATION, credential)  // Add an HTTP header.
                        .build();
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      